<template>
    <div class="wrap">
        <DeleteSpace :spaceNm="carSpaceInfo.spaceNm" :spaceId="carSpaceInfo.spaceId" v-if="ShowDelete"
                     @closeDelete="ShowDelete = false" @getList="getList" />
        <UnsubscribeReason v-if="unsubscribeReasonStatus" :spaceInfo="carSpaceInfo"
                           @closeunsubscribemodal="onCloseUnsubScribeModal" @expireDate="getExpireDate" @openFail="isCancelPaymentFail = true"
                           @openRefund="isCancelPaymentRefund = true" @openCancel="isCancelPayment = true" />
        <Unsubscribe v-if="ShowUnsub" :spaceInfo="carSpaceInfo" @closeModal="ShowUnsub = false"
                     @canclepayment="onCanclePayment" />
        <CancelPayment v-if="isCancelPayment" :spaceInfo="carSpaceInfo" :expireDate="expireDate"
                       @closeModal="isCancelPayment = false" />
        <CancelPaymentRefund v-if="isCancelPaymentRefund" :spaceInfo="carSpaceInfo"
                             @closeModal="isCancelPaymentRefund = false" />
        <CancelPaymentFail v-if="isCancelPaymentFail" :spaceInfo="carSpaceInfo" @closeModal="isCancelPaymentFail = false" />
        <UpdateSpaceModal v-if="updateSpaceStatus" @closeUpdateSpace="updateSpaceStatus = false" />
        <UpdateCancelCheck v-if="updateCancelStatus" @closeCheckModal="updateCancelStatus = false" @back="back" />
        <div class="space_pc" style="padding-bottom: 100px">
            <section class="main myspace w_940">
                <div class="main_box">
                    <div class="main_box_title" style="padding-bottom: 42px;">
                        <h1>스페이스 설정</h1>
                        <div @click="clickPre()">
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.81055 7.39387L16.8109 0.393555L18.8105 2.39324L11.8102 9.39355L18.8105 16.3939L16.8109 18.3936L9.81055 11.3932L2.81023 18.3936L0.810547 16.3939L7.81086 9.39355L0.810547 2.39324L2.81023 0.393555L9.81055 7.39387Z"
                                    fill="white" />
                            </svg>
                        </div>
                    </div>
                    <div class="cs_main">
                        <div class="myspace_content_form">
                            <form>
                                <div class="car_info">
                                    <div>
                                        <label>차량정보<span>*</span></label>
                                        <div class="select_box">
                                            <div class="select-box-area">
                                                <div class="label select-box" @mouseover.once="clickSelectBox()"
                                                     v-if="carSpaceInfo.carBrand == null">
                                                    브랜드
                                                </div>
                                                <div class="label select-box" @mouseover.once="clickSelectBox()" v-else>
                                                    {{ carSpaceInfo.carBrand }}
                                                </div>
                                                <div class="select-box-dropDown">
                                                    <ul>
                                                        <li v-for="item in carBrandList" :key="item.carBrandId"
                                                            @click="getCarKindList(item.carBrandId)" class="optionItem">
                                                            <p v-html="item.carBrandNm" />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="select-box-area2">
                                                <div class="label2 select-box2" @mouseover.once="clickSelectBox2()"
                                                     v-if="carSpaceInfo.carKind == null">
                                                    차종
                                                </div>
                                                <div class="label2 select-box2" @mouseover.once="clickSelectBox2()" v-else>
                                                    {{ carSpaceInfo.carKind }}
                                                </div>
                                                <div class="select-box-dropDown2">
                                                    <ul>
                                                        <li v-for="item in carKindList" :key="item.carKindId" class="optionItem2">
                                                            <p>{{ item.carKindNm }}</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <label>차량 번호<span>*</span></label>
                                        <input type="text" placeholder="차량번호를 입력해주세요." v-model="carSpaceInfo.spaceNm"
                                               @input="carNumCheck()" />
                                        <div class="in_pw" v-if="checkSpaceNm">
                                            <p style="
                          color: #ff4949 !important;
                          font-size: 16px;
                          margin: 0;
                        ">
                                                *올바른 차량 번호를 입력해주세요.
                                            </p>
                                        </div>
                                    </div>
                                    <!-- <div>
                    <label>소속 회사<span></span></label>
                    <div v-if="brandName === ''">
                      브랜드를 연결해주세요.
                    </div>
                    <div v-else>
                      {{ brandName }}
                    </div>
                  </div> -->
                                </div>
                            </form>
                            <div class="form_bottom" :class="carSpaceInfo.spacePayAt === 'T' ? 'only_one' : ''">
                                <p id="delet_space" style="margin-bottom: 0px"
                                   v-if="carSpaceInfo.monthlyPayCheck === 'N' && carSpaceInfo.spacePayAt !== 'T'"> </p>
                                <p id="delet_space" style="margin-bottom: 0px" @click="ShowDelete = true"
                                   v-else-if="carSpaceInfo.spacePayAt === 'S' || carSpaceInfo.spacePayAt === 'N'">
                                    스페이스 삭제
                                </p>
                                <p id="delet_space" style="margin-bottom: 0px" @click="ShowUnsub = true"
                                   v-else-if="carSpaceInfo.spacePayAt !== 'T'">
                                    구독 해지
                                </p>
                                <div>
                                    <button style="text-align: center"
                                            v-if="!checkSpaceNm && carSpaceInfo.carBrand != null && carSpaceInfo.spaceNm != null && carSpaceInfo.spaceNm.length > 0 && carSpaceInfo.carKind != null && carSpaceInfo.carKind !== '차종'"
                                            @click="updateSpaceInfo()">
                                        확인
                                    </button>
                                    <button v-else style="text-align: center; color: #5B5B5B; background: #4D1B24" disabled>확인</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <!-- 모바일 -->
        <div class="space_mo">
            <div class="back_dim"></div>
            <div class="mo_space_header">
                <div class="mo_header_box">
                    <a class="back_menu">
                        <img src="/media/images/icon_back_24.svg" @click="clickPre()">
                    </a>
                    <h2>스페이스 설정</h2>
                    <div class="btn_close" @click="clickPre()">
                        <img src="/media/images/icon_close_24.svg">
                    </div>
                </div>
            </div>
            <section class="main myspace">
                <div class="main_box">
                    <div class="cs_main">
                        <div class="myspace_content_form bt_pd">
                            <form>
                                <div>
                                    <label>차량정보 <span>*</span></label>
                                    <div class="select_box">
                                        <div class="select-box-area select-box-area3">
                                            <div class="select-box select_box_big" v-if="carSpaceInfo.carBrand === ''" @click="showSelectBrand">
                                                브랜드</div>
                                            <div class="select-box select_box_big" v-else @click="showSelectBrand">{{ carSpaceInfo.carBrand }}
                                            </div>
                                        </div>
                                        <div class="select-box-area select-box-area4">
                                            <div class="select-box select_box_small" v-if="carSpaceInfo.carKind === ''" @click="showSelectKind">
                                                차종</div>
                                            <div class="select-box select_box_small" v-else @click="showSelectKind">{{ carSpaceInfo.carKind }}
                                            </div>
                                        </div>
                                    </div>
                                    <label>차량 번호 <span>*</span>
                                        <div>
                                            <input type="text" placeholder="차량번호를 입력해주세요." v-model="carSpaceInfo.spaceNm"
                                                   @input="carNumCheck()" />
                                            <div class="in_pw" v-if="checkSpaceNm">
                                                <p style="
                            color: #ff4949 !important;
                            font-size: 12px;
                            margin: 0;
                          ">
                                                    *올바른 차량 번호를 입력해주세요.
                                                </p>
                                            </div>
                                        </div>
                                    </label>
                                    <!-- <label>소속회사 <span></span></label>
                  <div class="brand-name-box">
                    <div v-if="brandName === ''">소속 회사</div>
                    <div v-else>{{ brandName }}</div>
                  </div> -->
                                </div>
                            </form>
                            <p style="margin-top: 30px; margin-bottmo: 0px; font-size: 12px;"
                               v-if="carSpaceInfo.monthlyPayCheck === 'N' && carSpaceInfo.spacePayAt !== 'T'"> </p>
                            <p style="margin-top: 30px; margin-bottmo: 0px; color: white; font-size: 12px;"
                               v-if="carSpaceInfo.spacePayAt === 'S' || carSpaceInfo.spacePayAt === 'N'" @click="ShowDelete = true">스페이스
                                삭제</p>
                            <p style="margin-top: 30px; margin-bottmo: 0px; color: white; font-size: 12px;"
                               v-else-if="carSpaceInfo.spacePayAt !== 'T'" @click="ShowUnsub = true">
                                구독 해지
                            </p>
                            <div class="form_bottom">
                                <button style="text-align: center"
                                        v-if="!checkSpaceNm && carSpaceInfo.carBrand != null && carSpaceInfo.spaceNm != null && carSpaceInfo.spaceNm.length > 0 && carSpaceInfo.carKind != null && carSpaceInfo.carKind !== '차종'"
                                        @click="updateSpaceInfo()">
                                    수정
                                </button>
                                <button class="opacity3" style="text-align:center;" v-else>수정</button>
                            </div>
                            <div class="space_down_box1">
                                <div>
                                    <h2>브랜드</h2>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                         @click="hideSelectBrand">
                                        <path
                                            d="M12.0007 10.5862L16.9507 5.63623L18.3647 7.05023L13.4147 12.0002L18.3647 16.9502L16.9507 18.3642L12.0007 13.4142L7.05072 18.3642L5.63672 16.9502L10.5867 12.0002L5.63672 7.05023L7.05072 5.63623L12.0007 10.5862Z"
                                            fill="white" />
                                    </svg>
                                </div>
                                <ul>
                                    <li v-for="item in carBrandList" :key="item.carBrandId" @click="selectBrand(item)">
                                        <p v-html="item.carBrandNm" />
                                        <input type="hidden" :id="item.carBrandId" :value="item.carBrandNm">
                                    </li>
                                </ul>
                            </div>
                            <div class="space_down_box2">
                                <div>
                                    <h2>차종</h2>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                         @click="hideSelectKind">
                                        <path
                                            d="M12.0007 10.5862L16.9507 5.63623L18.3647 7.05023L13.4147 12.0002L18.3647 16.9502L16.9507 18.3642L12.0007 13.4142L7.05072 18.3642L5.63672 16.9502L10.5867 12.0002L5.63672 7.05023L7.05072 5.63623L12.0007 10.5862Z"
                                            fill="white" />
                                    </svg>
                                </div>
                                <ul>
                                    <li v-for="item in carKindList" :key="item.carKindId" @click="selectKind(item)">
                                        <p>{{ item.carKindNm }}</p>
                                        <input type="hidden" :id="item.carKindId" :value="item.carKindNm">
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import { defineComponent } from "@vue/composition-api";
import UpdateCancelCheck from "@/components/modal/Space/UpdateCancelCheck.vue";
import UpdateSpaceModal from "@/components/modal/Space/UpdateSpaceModal.vue";
import Unsubscribe from "@/components/modal/Space/Unsubscribe.vue";
import CancelPayment from "@/components/modal/Space/CancelPayment.vue";
import CancelPaymentRefund from "@/components/modal/Space/CancelPaymentRefund.vue";
import CancelPaymentFail from "@/components/modal/Space/CancelPaymentFail.vue";
import DeleteSpace from "@/components/modal/Space/DeleteSpace.vue";
import UnsubscribeReason from '@/components/modal/Space/UnsubscribeReason.vue';
import VueCookies from "vue-cookies";
import router from "@/router";
import dayjs from "dayjs";
import axios from "axios";
import $ from 'jquery';

export default defineComponent({
  data () {
    return {
      ShowUnsub: false,
      ShowUnsub2: false,
      carSpaceInfo: {},
      carBrandList: [],
      carKindList: [],
      checkSpaceNm: false,
      updateSpaceStatus: false,
      brandName: '',
      updateCancelStatus: false,
      isCancelPayment: false,
      isCancelPaymentRefund: false,
      isCancelPaymentFail: false,
      expireDate: '',
      ShowDelete: false,
      unsubscribeReasonStatus: false
    };
  },
  components: {
    Unsubscribe,
    UpdateSpaceModal,
    UpdateCancelCheck,
    CancelPayment,
    CancelPaymentRefund,
    CancelPaymentFail,
    DeleteSpace,
    UnsubscribeReason
  },
  methods: {
    getList () {
      router.push({ name: 'MySpace' });
    },
    getExpireDate (date) {
      this.expireDate = this.dateTime(date);
    },
    dateTime (value) {
      return dayjs(value).format("YYYY.MM.DD");
    },
    carNumCheck () { // 차량번호 유효성
      this.carSpaceInfo.spaceNm = this.carSpaceInfo.spaceNm.replace(/[^0-9|가-힣]/g, ''); // 숫자 한글 제외 입력 제한
      this.carSpaceInfo.spaceNm = this.carSpaceInfo.spaceNm.replace(/[|]/g, ''); // 특수문자 | 입력 방지
      /^.*(?=.{7})(?=.*[0-9])(?=.*[가-힣]).*$/.test(this.carSpaceInfo.spaceNm) ? this.checkSpaceNm = false : this.checkSpaceNm = true; // 한글 + 숫자 유효성체크
      if (this.carSpaceInfo.spaceNm === '') {
        this.checkSpaceNm = true;
      }
    },
    set () {
      $('.back_dim').hide();
    },
    showSelectBrand () {
      $(document).on("click", ".select_box .select-box-area3", () => {
        $(".space_down_box1").css({ bottom: "0", transition: "all 0.5s" });
      });
      $(".space_down_box2").attr("style", "transition : all 1s !important");
    },
    showSelectKind () {
      $(document).off("click").on("click", ".select_box .select-box-area4", () => {
        $(".space_down_box2").css({ bottom: "0", transition: "all 0.5s" });
      });
      $(".space_down_box1").attr("style", "transition : all 1s !important");
    },
    selectBrand (item) {
      this.carSpaceInfo.carBrand = document.getElementById(item.carBrandId).value;
      this.carSpaceInfo.carKind = "차종";
      this.getCarKindList(item.carBrandId);

      $(".back_dim").hide();
      $(".space_down_box1").attr("style", "transition : all 1s !important");
    },
    selectKind (item) {
      this.carSpaceInfo.carKind = document.getElementById(item.carKindId).value;

      $(".back_dim").hide();
      $(".space_down_box2").attr("style", "transition : all 1s !important");
    },
    hideSelectBrand () {
      // $(".space_down_box1").find('svg').on('click', function () {
      $(".back_dim").hide();
      $(".space_down_box1").attr("style", "transition : all 1s !important");
      // });
    },
    hideSelectKind () {
      // $(".space_down_box2").find('svg').on('click', function () {
      $(".back_dim").hide();
      $(".space_down_box2").attr("style", "transition : all 1s !important");
      // });
    },
    clickPre () {
      this.updateCancelStatus = true;
    },
    back () {
      history.back();
    },
    updateSpaceInfo () {
      if (this.carSpaceInfo.spaceNm.length > 0) {
        // 차량 정보 수정
        var noticeTypeDetail = VueCookies.get('userId');
        var aToken = VueCookies.get("aToken");
        var headers = { "X-AUTH-TOKEN": aToken };
        var spaceInfo = this.carSpaceInfo;
        VueCookies.set("spaceNm", spaceInfo.spaceNm); // 스페이스명 쿠키등록

        axios
          .post("/api/profile/updateSpaceInfo", spaceInfo, { headers })
          .then((res) => {
            // console.log(res.data.result);
            var spaceId = spaceInfo.spaceId;

            axios
              .post('/api/menu/updateSpaceNotice', { spaceId, noticeTypeDetail }, { headers })
              .then(res => {
                // console.log(res.data.result);
                this.updateSpaceStatus = true;
              })
              .catch(err => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.checkSpaceNm = false;
      }
    },
    async getSpaceInfo () {
      // 등록된 차량정보 가져오기
      var spaceId = localStorage.getItem("spaceId");
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      await axios
        .post("/api/profile/getMySpaceInfo", { spaceId }, { headers })
        .then((res) => {
          // console.log(res.data.result);
          this.carSpaceInfo = res.data.result;
          this.getBrandInfo();
          this.getCarBrandList();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getCarBrandList () {
      // 차 브랜드 목록 가져오기
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      await axios
        .post("/api/profile/getCarBrandList", null, { headers })
        .then((res) => {
          // console.log(res.data.result);
          this.carBrandList = res.data.result;

          var carBrandNm = this.carSpaceInfo.carBrand;
          for (var i in this.carBrandList) {
            var kindId = this.carBrandList[i].carBrandNm.indexOf(carBrandNm);
            if (kindId === 0) {
              var kindInfo = this.carBrandList[i];
              this.getCarKindList(kindInfo.carBrandId);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCarKindList (item) {
      // 차 종류 목록 가져오기
      var carBrandId = item;
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post("/api/profile/getCarKindList", { carBrandId }, { headers })
        .then((res) => {
          // console.log(res.data.result);
          this.carKindList = res.data.result;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getBrandInfo () {
      var brandId = this.carSpaceInfo.brandId;
      if (brandId != null) {
        var aToken = VueCookies.get("aToken");
        var headers = { "X-AUTH-TOKEN": aToken };

        axios
          .post('/api/profile/getBrand', { brandId }, { headers })
          .then(res => {
            // console.log(res.data.result);
            this.brandName = res.data.result.brandNm;
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    clickSelectBox () {
      /* select_box_custom */
      var label = document.querySelectorAll('.label');
      label.forEach(function (lb) {
        lb.addEventListener('click', e => {
          var optionList = lb.nextElementSibling;
          var optionItems = optionList.querySelectorAll('.optionItem');
          clickLabel(lb, optionItems);
        });
      });
      var clickLabel = (lb, optionItems) => {
        //* ***************** 추가된 부분 START
        // 내가 아닌 다른 select 닫기
        label.forEach(function (itemLb) {
          if (lb !== itemLb) {
            itemLb.parentNode.classList.remove('active');
          }
        });
        //* ***************** 추가된 부분 END
        if (lb.parentNode.classList.contains('active')) {
          lb.parentNode.classList.remove('active');
          optionItems.forEach((opt) => {
            opt.removeEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        } else {
          lb.parentNode.classList.add('active');
          optionItems.forEach((opt) => {
            opt.addEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        }
      };
      var handleSelect = (label, item) => {
        this.carSpaceInfo.carBrand = item.textContent;
        this.carSpaceInfo.carKind = "차종";
        label.parentNode.classList.remove('active');
      };
      var handleClose = e => {
        // select 가 아닌경우 닫기
        if (!e.target.classList.contains('label') && !e.target.classList.contains('optionItem')) {
          label.forEach(function (lb) {
            lb.parentNode.classList.remove('active');
          });
        }
      };
      window.addEventListener('click', e => handleClose(e));
    },
    clickSelectBox2 () {
      /* select_box_custom */
      var label = document.querySelectorAll('.label2');
      label.forEach(function (lb) {
        lb.addEventListener('click', e => {
          var optionList = lb.nextElementSibling;
          var optionItems = optionList.querySelectorAll('.optionItem2');
          clickLabel(lb, optionItems);
        });
      });
      var clickLabel = (lb, optionItems) => {
        //* ***************** 추가된 부분 START
        // 내가 아닌 다른 select 닫기
        label.forEach(function (itemLb) {
          if (lb !== itemLb) {
            itemLb.parentNode.classList.remove('active');
          }
        });
        //* ***************** 추가된 부분 END
        if (lb.parentNode.classList.contains('active')) {
          lb.parentNode.classList.remove('active');
          optionItems.forEach((opt) => {
            opt.removeEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        } else {
          lb.parentNode.classList.add('active');
          optionItems.forEach((opt) => {
            opt.addEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        }
      };
      var handleSelect = (label, item) => {
        this.carSpaceInfo.carKind = item.textContent;
        label.parentNode.classList.remove('active');
      };
      var handleClose = e => {
        // select 가 아닌경우 닫기
        if (!e.target.classList.contains('label2') && !e.target.classList.contains('optionItem2')) {
          label.forEach(function (lb) {
            lb.parentNode.classList.remove('active');
          });
        }
      };
      window.addEventListener('click', e => handleClose(e));
    },
    onCanclePayment () {
      this.ShowUnsub = false;
      this.unsubscribeReasonStatus = true;
    },
    onCloseUnsubScribeModal () {
      this.unsubscribeReasonStatus = false;
    }
  },
  async mounted () {
    await this.getSpaceInfo();
    await this.getCarBrandList();
    this.set();
  }
});
</script>
<style scoped>
.space_down_box1 ul li p {
  margin: 0;
}

.space_down_box2 ul li p {
  margin: 0;
}

.select_box {
  margin-bottom: 16px;
}

.car_info>div:nth-child(3) div {
  width: 50%;
  margin-top: 8px;
  padding: 18px 16px;
  border-radius: 8px;
  background: #2a2a2a;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.84);
}

@media all and (max-width: 500px) {
  .alert_box {
    padding-top: 0px;
  }

  .brand-name-box {
    width: 50%;
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 14px 16px;
    border-radius: 8px;
    background: #2a2a2a;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.84);
  }
}
</style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
